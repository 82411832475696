
/*
 * Page | Home
 */

 import { IRouteHandler, Page } from "@sygnal/sse";

export class HomePage implements IRouteHandler {

//  modelDropdown: WebflowDropdown; 

  constructor() {
  }
  setup() {

//    loadEngineCSS("site.css"); 
   
  }

  exec() {  

//    console.log("Home."); 

  }

}
